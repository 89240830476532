import VrtLogo from '/public/static/vrt-logos/vrt/svg/vrt-black.svg'

export default function PageNotFound(): JSX.Element {
	return (
		<div className="h-screen flex flex-col justify-center items-center">
			<VrtLogo className={'h-14 items-center'} />
			<span className="text-max-dark-blue text-2xl pt-8">
				Deze pagina kan niet gevonden worden.
			</span>
		</div>
	)
}
